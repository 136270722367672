<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Academic Years</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Academic-Years' }"
              >Academic Years</router-link
            >
          </li>
          <li class="breadcrumb-item active">Add New</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9 col-sm-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Add New Academic Year</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->

            <div class="basic-form">
              <form
                id="academic_years_create"
                @submit.prevent="submitFormData()"
              >
                <div class="form-group row">
                  <label
                    class="col-md-12 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> Year
                  </label>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label"
                    >Name <span style="color: red">*</span></label
                  >
                  <div class="col-md-10">
                    <input
                      type="text"
                      required
                      class="form-control"
                      v-model="year.name"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label"
                    >Start Date <span style="color: red">*</span></label
                  >
                  <div class="col-md-10">
                    <input
                      type="date"
                      required
                      class="form-control"
                      v-model="year.start_date"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-2 col-form-label"
                    >End Date <span style="color: red">*</span></label
                  >
                  <div class="col-md-10">
                    <input
                      type="date"
                      required
                      class="form-control"
                      v-model="year.end_date"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="col-md-12 col-form-label"
                    style="color: rgb(120, 27, 32)"
                  >
                    <i class="custom-left-border"></i> Terms
                  </label>
                </div>

                <div v-for="(term, index) in year.terms" :key="index">
                  <!-- Delete the block -->
                  <div v-if="index != 0" class="form-group row">
                    <div class="col-md-2">
                      <a
                        @click.prevent="removeTermBlock(index)"
                        href="#"
                        class="btn btn-danger"
                        title="Rmove"
                        ><i class="la la-times"></i> </a
                      ><br />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Name <span style="color: red">*</span></label
                      >
                      <input
                        required
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        v-model="term.name"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >Start Date <span style="color: red">*</span></label
                      >
                      <input
                        required
                        type="date"
                        class="form-control"
                        v-model="term.start_date"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label class="col-form-label"
                        >End Date <span style="color: red">*</span></label
                      >
                      <input
                        required
                        type="date"
                        class="form-control"
                        v-model="term.end_date"
                      />
                    </div>
                  </div>
                </div>

                <!-- Add new block -->
                <div class="from-group row">
                  <div class="col-md-2 col-sm-6">
                    <a
                      @click.prevent="addAnotherTermBlock"
                      href="#"
                      class="btn btn-secondary-original"
                      onclick="this.blur();"
                      ><i class="la la-plus"></i> Another Term</a
                    ><br />
                  </div>
                </div>

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useAcademicYears from "@/composables/academic_years";
import { inject, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FeedbackMessages,
  },
  setup() {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const year = ref({
      name: "",
      start_date: "",
      end_date: "",
      terms: [
        {
          name: "",
          start_date: "",
          end_date: "",
        },
      ],
    });

    const { storeAcademicYear, msgs, errors } = useAcademicYears();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("add_academic_years")) {
        router.push({
          name: "401",
        });
      }
    });

    const addAnotherTermBlock = () => {
      year.value.terms.push({
        name: "",
        start_date: "",
        end_date: "",
      });
    };

    const removeTermBlock = (index) => {
      year.value.terms.splice(index, 1);
    };

    const submitFormData = async () => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await storeAcademicYear(year).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Academic-Years-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      addAnotherTermBlock,
      removeTermBlock,
      year,
      disableBtn,
      msgs,
      errors,
    };
  },
};
</script>
